import * as React from "react";
import {
  CountDownTimerText,
  CountDownTimerContent,
  Number,
  TimeHolder,
} from "./CountdownTimer.styled";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import type { CountdownTimerProps } from "./CountDownTimer-helper";
import { ensureTwoDigitNumber } from "./CountDownTimer-helper";
import { useCountdownTimer } from "./hooks/useCountdownTimer";
import { useXxlMediaQuery } from "../../hooks/useXxlMediaQuery";
import { XxlStack } from "../Common/XxlStack";
import spaces from "../../styles/theme/spaces";
import { Stopwatch } from "@xxl/icons";
import { color } from "@xxl/theme";
import type { TextProps } from "../Text";
import type { CountdownSizes } from "../Banners/Shared/BannerContent/BannerContent.helper";

const CountdownTimer = (props: CountdownTimerProps) => {
  const {
    startDate,
    date: endDate,
    description,
    isBlackText,
    isOnlyHours = false,
    size,
    center = false,
  } = props;
  const { t } = useTranslations();
  const isLaptop = useXxlMediaQuery("LaptopMediaQuery");
  const { days, hasExpired, hours, min, sec } = useCountdownTimer({
    endDate,
    isOnlyHours,
    startDate,
  });
  if (hasExpired) {
    return null;
  }

  const textSize = size ?? (isLaptop ? "big" : "base");

  const textProps: TextProps = {
    as: "span",
    fontFamily: "bold",
    size: textSize,
  };

  const iconSizes: { [key in CountdownSizes]: number } = {
    big: 24,
    base: 20,
    small: 17,
    tiny: 17,
  };

  return (
    <CountDownTimerContent>
      <CountDownTimerText isBlackText={isBlackText}>
        <XxlStack
          flexDirection={center ? "column" : "row"}
          flexWrap={"wrap"}
          rowGap={spaces.micro}
          alignItems={"center"}
        >
          {description !== undefined && (
            <TimeHolder {...textProps}>{props.description}&nbsp;</TimeHolder>
          )}
          <XxlStack alignItems={"center"} flexDirection={"row"}>
            <Stopwatch
              fontSize={iconSizes[textSize]}
              color={isBlackText ? color.webBlack.hex : color.white.hex}
            />
            &nbsp;
            {days > 0 && (
              <TimeHolder {...textProps}>
                <Number>{days}</Number>
                {t("countdowntimer.days").charAt(0)}
                &nbsp;:&nbsp;
              </TimeHolder>
            )}
            <TimeHolder {...textProps}>
              <Number data-testid="timer-hours">{hours}</Number>
              {t("countdowntimer.hours").charAt(0)}
              &nbsp;:&nbsp;
            </TimeHolder>
            <TimeHolder {...textProps}>
              <Number data-testid="timer-minutes">
                {ensureTwoDigitNumber(min)}
              </Number>
              {t("countdowntimer.min").charAt(0)}
              &nbsp;:&nbsp;
            </TimeHolder>
            <TimeHolder {...textProps}>
              <Number data-testid="timer-seconds">
                <Number>{ensureTwoDigitNumber(sec)}</Number>
              </Number>
              {t("countdowntimer.sec").charAt(0)}
            </TimeHolder>
          </XxlStack>
        </XxlStack>
      </CountDownTimerText>
    </CountDownTimerContent>
  );
};

export { CountdownTimer };
