const commonStyles = {
  fontFamily: "var(--font-family-medium)",
  fontSize: 15,
  lineHeight: 1.2,
};

export default {
  smallContent: {
    ...commonStyles,
    fontSize: 10,
    tablet: {
      fontSize: 15,
    },
  },
  mediumContent: {
    ...commonStyles,
    fontSize: 12,
    tablet: {
      fontSize: 20,
    },
  },
};
