const commonStyles = {
  lineHeight: 1.2,
  letterSpacing: -0.15,
  tablet: {
    fontSize: 15,
  },
};

export default {
  brand: {
    fontFamily: "var(--font-family-bold)",
    fontSize: 12,
    ...commonStyles,
  },
  name: {
    fontSize: 12,
    ...commonStyles,
  },
  description: {
    fontSize: 10,
    ...commonStyles,
  },
  cheapestPrice: {
    fontSize: 10,
    ...commonStyles,
  },
};
