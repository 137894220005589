import React from "react";
import ConditionalLink from "../ConditionalLink/ConditionalLink";
import { BannerSize } from "../PriceDisplays/price-display-helper";
import {
  BannerWrapper,
  ExtraSmallBannerWrapper,
  MediumBannerWrapper,
  SmallBannerWrapper,
} from "./Banner.styled";

type BannerProps = {
  size: BannerSize;
  url?: string;
  children: React.ReactNode;
};

const BannerSizeWrapper = ({
  bannerSize,
  children,
}: {
  bannerSize: BannerSize;
  children: React.ReactNode;
}) => {
  const Component = {
    [BannerSize.XS]: ExtraSmallBannerWrapper,
    [BannerSize.SMALL]: SmallBannerWrapper,
    [BannerSize.MEDIUM]: MediumBannerWrapper,
  }[bannerSize];
  return <Component>{children}</Component>;
};

const isBannerFullWidth = (size: BannerSize) => size !== BannerSize.XS;

const Banner: React.FunctionComponent<BannerProps> = ({
  size,
  url,
  children,
}) => {
  const isBannerALink = Boolean(url);

  return (
    <BannerWrapper isBannerFullWidth={isBannerFullWidth(size)}>
      <ConditionalLink
        url={url ?? ""}
        condition={isBannerALink}
        className="banner"
      >
        <BannerSizeWrapper bannerSize={size}>{children}</BannerSizeWrapper>
      </ConditionalLink>
    </BannerWrapper>
  );
};

export default Banner;
