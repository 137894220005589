import React from "react";
import styled from "@emotion/styled/macro";
import type { PropsWithChildren } from "react";

const StyledA = styled.a`
  all: unset;
  cursor: pointer;
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
`;

type Props = {
  condition: boolean;
  url: string;
  className?: string;
  style?: React.CSSProperties;
};

const ConditionalLink = ({
  condition,
  children,
  url,
  className,
  style,
}: PropsWithChildren<Props>) => {
  if (!condition && className === undefined) {
    return <>{children}</>;
  }
  if (!condition) {
    return (
      <div className={className} style={style}>
        {children}
      </div>
    );
  }
  return (
    <StyledA href={url} className={className} style={style}>
      {children}
    </StyledA>
  );
};

export default ConditionalLink;
