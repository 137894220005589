import { BannerSize } from "../../../PriceDisplays/price-display-helper";

const commonStyles = {
  fontFamily: "var(--font-family-bold)",
  lineHeight: 1,
};

export default {
  priceContent: {
    [BannerSize.XS]: {},
    [BannerSize.SMALL]: {
      ...commonStyles,
      fontSize: 26,
      tablet: {
        fontSize: 34,
      },
    },
    [BannerSize.MEDIUM]: {
      ...commonStyles,
      fontSize: 36,
      laptop: {
        fontSize: 60,
      },
    },
  },
  discountContent: {
    [BannerSize.XS]: {},
    [BannerSize.SMALL]: {
      ...commonStyles,
      fontSize: 20,
      tablet: {
        fontSize: 24,
      },
    },
    [BannerSize.MEDIUM]: {
      ...commonStyles,
      fontSize: 26,
      laptop: {
        fontSize: 34,
      },
    },
  },
};
