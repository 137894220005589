import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import { MQ } from "../../styles/helpers";
import { xxlTheme } from "../../styles/xxl-theme";
import { Text } from "../Text";

const { colors } = xxlTheme;

export const CountDownTimerText = styled.div<{
  isBlackText: boolean;
}>(
  ({ isBlackText }) => css`
    font-weight: normal;
    font-family: var(--font-family-medium);
    font-size: 15px;
    line-height: 1;
    padding: 0;
    margin: 2px 0 0;
    text-align: left;
    width: auto;

    ${MQ("laptop")} {
      font-size: 20px;
      line-height: 1;
    }

    &&& {
      span {
        color: ${isBlackText ? colors.xxlBlack : colors.xxlWhite};
      }
    }
  `
);

export const CountDownTimerContent = styled.div`
  padding: 0;
  text-align: left;
`;

export const TimeHolder = styled(Text)`
  line-height: 1;
`;

export const Number = styled.span`
  font-family: var(--font-family-bold);
  display: inline-block;
`;
