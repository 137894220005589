import isPropValid from "@emotion/is-prop-valid";
import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { ribbonLeft } from "../../../../styled/mixins";
import { MQ, typographyToCss } from "../../../../styles/helpers";
import { colorsAsCssVariable as colors } from "../../../../styles/theme/colors";
import spaces from "../../../../styles/theme/spaces";
import { BannerSize } from "../../../PriceDisplays/price-display-helper";
import text from "./Ribbon.typography";

export type RibbonCommonProps = {
  foregroundColor?: string;
  backgroundColor?: string;
  bannerSize: string;
};

type RibbonProps = {
  bannerSize: BannerSize;
} & RibbonCommonProps;

const smallHeight = "34px" as const;
const smallMobileHeight = "24px" as const;
const mediumHeight = "50px" as const;
const mediumMobileHeight = "28px" as const;

export const RibbonWrapper = styled.span<RibbonProps>(
  ({ backgroundColor, bannerSize }) => css`
    display: flex;
    height: ${bannerSize === BannerSize.MEDIUM
      ? mediumMobileHeight
      : smallMobileHeight};
    margin-left: auto;
    background-color: transparent;

    &::before {
      width: 0;
      height: 0;
      border-right: 1px solid ${backgroundColor ?? colors.xxlGreen};
      border-top: ${backgroundColor ?? colors.xxlGreen};
      border-top-style: solid;
      ${ribbonLeft(
        bannerSize === BannerSize.MEDIUM
          ? mediumMobileHeight
          : smallMobileHeight
      )};
      content: "";
    }

    ${MQ("tablet")} {
      height: ${bannerSize === BannerSize.MEDIUM ? mediumHeight : smallHeight};

      &::before {
        ${ribbonLeft(
          bannerSize === BannerSize.MEDIUM ? mediumHeight : smallHeight
        )};
      }
    }
  `
);

export const RibbonContentCommon = styled("div", {
  shouldForwardProp: isPropValid,
})<RibbonCommonProps>(
  ({ foregroundColor, backgroundColor, bannerSize }) => css`
    display: flex;
    align-items: center;
    padding: 0 ${spaces.miniMicro};
    color: ${foregroundColor ?? colors.xxlBlack};
    white-space: nowrap;
    text-align: center;
    background-color: ${backgroundColor ?? colors.xxlGreen};
    border-color: ${backgroundColor ?? colors.xxlGreen};

    ${MQ("laptop")} {
      padding: 0
        ${bannerSize === BannerSize.MEDIUM
          ? spaces.smallRegular
          : spaces.miniMicro};
    }
  `
);

export const RibbonContent = styled(RibbonContentCommon)<RibbonProps>(
  ({ bannerSize }) => css`
    ${typographyToCss(
      bannerSize === BannerSize.MEDIUM ? text.mediumContent : text.smallContent
    )};
  `
);

export const Label = styled.span`
  text-transform: lowercase;
  &::first-letter {
    text-transform: uppercase;
  }
`;
