import type * as CSSType from "csstype";
import * as React from "react";
import type { BannerSize } from "../../../PriceDisplays/price-display-helper";
import { Label } from "../../ProductBanner/Ribbon/Ribbon.styled";
import { RibbonContent, RibbonWrapper } from "./PriceInfoRibbon.styled";

interface HighlightedPriceInfoRibbonProps {
  bannerSize: BannerSize;
  colors: {
    background: CSSType.Property.Color;
    foreground: CSSType.Property.Color;
  };
  isDiscountInfo: boolean;
  label: string;
}

const HighlightedPriceInfoRibbon = ({
  label,
  colors: { background, foreground },
  isDiscountInfo,
  bannerSize,
}: HighlightedPriceInfoRibbonProps) => (
  <RibbonWrapper
    backgroundColor={background}
    isDiscountInfo={isDiscountInfo}
    bannerSize={bannerSize}
  >
    <RibbonContent
      foregroundColor={foreground}
      backgroundColor={background}
      isDiscountInfo={isDiscountInfo}
      bannerSize={bannerSize}
    >
      <Label>{label}</Label>
    </RibbonContent>
  </RibbonWrapper>
);

export { HighlightedPriceInfoRibbon };
