export default {
  bannerLabel: {
    fontSize: 12,
    lineHeight: 1.2,
    letterSpacing: -0.15,
  },
  bannerHeadline: {
    fontSize: 24,
    lineHeight: 1.2,
    letterSpacing: -0.15,
    laptop: {
      fontSize: 34,
    },
  },
  extraSmallLabel: {
    fontFamily: "var(--font-family-medium)",
  },
  extraSmallHeadline: {
    fontSize: 20,
  },
  extraSmallDescription: {
    fontSize: 10,
    laptop: {
      fontSize: 15,
    },
  },
  bannerDescription: {
    fontSize: 15,
    lineHeight: 1.2,
    letterSpacing: -0.15,
  },
  buttonWithIcon: {
    fontFamily: "var(--font-family-bold)",
    fontSize: 12,
    laptop: {
      fontSize: 15,
    },
  },
};
