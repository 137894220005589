import type * as CSSType from "csstype";
import * as React from "react";
import type { BannerSize } from "../../../PriceDisplays/price-display-helper";
import { Label, RibbonContent, RibbonWrapper } from "./Ribbon.styled";

interface RibbonProps {
  bannerSize: BannerSize;
  colors: {
    background: CSSType.Property.Color;
    foreground: CSSType.Property.Color;
  };
  label: string;
}

const Ribbon = ({
  bannerSize,
  colors: { background, foreground },
  label,
}: RibbonProps) => (
  <RibbonWrapper bannerSize={bannerSize} backgroundColor={background}>
    <RibbonContent
      bannerSize={bannerSize}
      foregroundColor={foreground}
      backgroundColor={background}
    >
      <Label>{label}</Label>
    </RibbonContent>
  </RibbonWrapper>
);

export { Ribbon };
