import { isNotNullOrUndefined } from "@xxl/common-utils";
import type { GridBanner } from "@xxl/content-api";
import { GridBannerTypeEnum } from "@xxl/content-api";
import { default as React } from "react";
import Banner from "../Banners/Banner";
import HighlightedProductBanner from "../Banners/HighlightedProductBanner/HighlightedProductBanner";
import ProductBanner from "../Banners/ProductBanner/ProductBanner";
import RegularBanner from "../Banners/RegularBanner/RegularBanner";
import type { SanityTextColor } from "../Banners/types";
import type { ProductBannerPricing } from "../PriceDisplays/price-display-helper";
import { BannerSize } from "../PriceDisplays/price-display-helper";
import { ComponentWrapper } from "./GridComponent.styled";
import { log } from "@xxl/logging-utils";

export type GridComponentProps = {
  banners: GridBanner[];
  priceData?: ProductBannerPricing[];
};

export const resolveTextColor = (colorTitle?: string): SanityTextColor =>
  colorTitle === "XXL Black" ? "black" : "white";

const resolveBannerSize = (type?: GridBannerTypeEnum): BannerSize => {
  switch (type) {
    case GridBannerTypeEnum.xsProductBanner:
    case GridBannerTypeEnum.gridBanner:
      return BannerSize.XS;
    case GridBannerTypeEnum.smallProductBanner:
    case GridBannerTypeEnum.smallBanner:
    case GridBannerTypeEnum.highlightedSmallProductBanner:
      return BannerSize.SMALL;
    default:
      return BannerSize.MEDIUM;
  }
};

const RenderProperBanner = ({
  banner,
  priceData = [],
}: {
  banner: GridBanner;
  positionInHomepage: number;
  priceData?: ProductBannerPricing[];
}) => {
  const textColor = resolveTextColor(banner.textColorPicker?.title);
  const size = resolveBannerSize(banner._type);
  const positionInHomepage = 0; // assuming placement high up on page for campaigns

  if (
    banner._type === "smallBanner" ||
    banner._type === "mediumBanner" ||
    banner._type === "gridBanner"
  ) {
    return (
      <RegularBanner
        tagline={banner.typeText}
        title={banner.text}
        description={banner.description}
        taglineField={banner.tagline}
        titleField={banner.titleField}
        descriptionField={banner.descriptionField}
        size={size}
        url={banner.url}
        textColor={textColor}
        textPosition={banner.textPosition}
        hasImageOverlay={Boolean(banner.imageOverlay)}
        image={banner.image}
        countdownTimerSettings={banner.countdownTimerSettings}
        backgroundColor={banner.backgroundColor?.value}
        buttonText={banner.button}
        positionInHomepage={positionInHomepage}
      />
    );
  }

  if (priceData.length === 0) {
    return null;
  }

  if (
    banner._type === "xsProductBanner" ||
    banner._type === "smallProductBanner" ||
    banner._type === "mediumProductBanner"
  ) {
    const price = priceData.find(
      ({ productCode, type }) =>
        banner.product?.productCode === productCode && banner._type === type
    );

    if (!isNotNullOrUndefined(price)) {
      log.error(`Banner of type "${banner._type}" has no price.`);
      return null;
    }
    if (
      price.type !== "xsProductBanner" &&
      price.type !== "smallProductBanner" &&
      price.type !== "mediumProductBanner"
    ) {
      log.error(
        `Banner of type "${banner._type}" has mismacthing price type "${price.type}"`
      );
      return null;
    }

    return (
      <ProductBanner
        backgroundColor={banner.backgroundColor?.value}
        buttonLabel={banner.button}
        cheapestPrice={banner.additionalPriceLabel}
        countdownTimerSettings={banner.countdownTimerSettings}
        icon={banner.icon}
        positionInHomepage={positionInHomepage}
        priceData={price}
        product={banner.product}
        productBrand={banner.productBrand}
        productDescription={banner.productDescription}
        productName={banner.productName}
        size={size}
        textColor={textColor}
      />
    );
  }
  if (
    banner._type === "highlightedSmallProductBanner" ||
    banner._type === "highlightedMediumProductBanner"
  ) {
    const price = priceData.find(
      ({ productCode, type }) =>
        banner.product?.productCode === productCode && banner._type === type
    );

    if (!isNotNullOrUndefined(price)) {
      log.error(`Banner of type "${banner._type}" has no price.`);
      return null;
    }
    if (
      price.type !== "highlightedSmallProductBanner" &&
      price.type !== "highlightedMediumProductBanner"
    ) {
      log.error(
        `Banner of type "${banner._type}" has mismacthing price type "${price.type}"`
      );
      return null;
    }

    return (
      <>
        <HighlightedProductBanner
          backgroundColor={banner.backgroundColor?.value}
          cheapestPrice={banner.additionalPriceLabel}
          countdownTimerSettings={banner.countdownTimerSettings}
          headline={banner.headline}
          icon={banner.icon}
          label={banner.typeText}
          logo={banner.logo}
          positionInHomepage={positionInHomepage}
          priceData={price}
          product={banner.product}
          productDescription={banner.productDescription}
          productName={banner.productName}
          size={size}
          subtitle={banner.subtitle}
          textColor={textColor}
        />
      </>
    );
  }
  return null;
};

const GridComponent: React.FunctionComponent<GridComponentProps> = ({
  banners,
  priceData,
}) => (
  <ComponentWrapper>
    {banners.map((item, index) => (
      <Banner
        key={index}
        size={resolveBannerSize(item._type)}
        url={item.url !== undefined ? item.url : item.product?.url}
      >
        <RenderProperBanner
          banner={item}
          priceData={priceData}
          positionInHomepage={0}
        />
      </Banner>
    ))}
  </ComponentWrapper>
);

export default GridComponent;
