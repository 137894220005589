import type { TextProps } from "../Text";

type GetCountdownTimerDataProps = {
  dateNow: Date;
  isBlackString: string;
  isOnlyHoursString: string;
  endDateString?: string;
  startDateString?: string;
};

type CountdownTimerProps = {
  date: Date;
  description?: string;
  isBlackText: boolean;
  isOnlyHours?: boolean;
  startDate?: Date;
  size?: TextProps["size"];
  center?: boolean;
};

const ensureTwoDigitNumber = (value: number) =>
  value.toString().length === 1 ? `0${value}` : `${value}`;

const getCountdownTimerData = ({
  startDateString,
  endDateString,
  dateNow,
  isBlackString,
  isOnlyHoursString,
}: GetCountdownTimerDataProps): CountdownTimerProps => {
  if (endDateString === undefined) {
    throw new Error("End date is not defined");
  }
  const endDate = new Date(endDateString);
  const startDate =
    typeof startDateString === "string" && startDateString !== ""
      ? new Date(startDateString)
      : undefined;

  if (endDate < dateNow) {
    throw new Error("Date is in the past");
  }

  return {
    startDate,
    date: endDate,
    isBlackText: isBlackString === "true",
    isOnlyHours: isOnlyHoursString === "true",
  };
};

export type { CountdownTimerProps, GetCountdownTimerDataProps };

export { ensureTwoDigitNumber, getCountdownTimerData };
