const commonStyles = {
  lineHeight: 1.2,
  letterSpacing: -0.15,
  tablet: {
    fontSize: 15,
  },
};

export default {
  label: {
    ...commonStyles,
    fontSize: 12,
  },
  smallBannerHeadline: {
    fontFamily: "var(--font-family-bold)",
    fontSize: 20,
    lineHeight: 1.2,
    letterSpacing: -0.15,
    laptop: {
      fontSize: 24,
    },
  },
  headline: {
    ...commonStyles,
    fontFamily: "var(--font-family-bold)",
    fontSize: 26,
    tablet: {
      fontSize: 34,
    },
  },
  subtitle: {
    ...commonStyles,
    fontFamily: "var(--font-family-medium)",
    fontSize: 15,
  },
  name: {
    ...commonStyles,
    fontFamily: "var(--font-family-bold)",
    fontSize: 15,
  },
  description: {
    ...commonStyles,
    fontSize: 15,
  },
  cheapestPrice: {
    fontSize: 10,
    ...commonStyles,
  },
};
